import request from './request.js'

export function getOrder(data) {
    return request({
        method: "post",
        url: "/web/user/order",
        data
    })
}
export function getDevice(data) {
    return request({
        method: "post",
        url: "/web/user/device",
        data
    })
}
export function getNeed(data) {
    return request({
        method: "post",
        url: "/web/user/seekrent",
        data
    })
}
export function getRental(data) {
    return request({
        method: "post",
        //url: "/web/user/rental/orders",
        url: "/h5/user/order",
        data
    })
}
export function getUser() {
    return request({
        method: "post",
        url: "/web/user/info",
    })
}
export function updateDevice(data) {
    return request({
        method: "post",
        url: "/web/device_lease/edit",
        data
    })
}
export function delDevice(data) {
    return request({
        method: "post",
        url: "/web/device_lease/delete",
        data
    })
}
export function updateNeed(data) {
    return request({
        method: "post",
        url: "/web/device_seekrent/edit",
        data
    })
}
export function delNeed(data) {
    return request({
        method: "post",
        url: "/web/device_seekrent/delete",
        data
    })
}
export function getupdateDevice(data) {
    return request({
        method: "post",
        url: "/web/device_lease/find",
        data
    })
}
export function getupdateneed(data) {
    return request({
        method: "post",
        url: "/web/device_seekrent/find",
        data
    })
}
export function update_order(data) {
    return request({
        method: "post",
        url: "/web/order/edit",
        data
    })
}
export function update_order1(data) {
    return request({
        method: "post",
        url: "/web/order/edit1",
        data
    })
}
export function getmessage(data) {
    return request({
        method: "post",
        url: "/web/message",
        data
    })
}

export function getsysmessage(data) {
    return request({
        method: "post",
        url: "/web/message/find",
        data
    })
}

export function message_state(data) {
    return request({
        method: "post",
        url: "/web/message/edit",
        data
    })
}
export function ModifyOrder(data) {
    return request({
        method: "post",
        url: "/web/user/order1",
        data
    })
}