<template>
  <div class="list-manage my-order">
    <section class="list-manage-table">
      <div class="table-title"><span class="title">我的订单</span>

      </div>
      <div class="thead">
        <span class="th">设备</span>
        <span class="th">租赁方式</span>
        <span class="th">数量</span>
        <span class="th">小计</span>
      </div>
      <div class="table-title2">
        <div class="device-map" >
            <input type="text" placeholder="请输入关键词搜索" class="input"/>
            <div class="btn-search">搜索</div>
        </div>
      </div>
      <div class="tbody" v-loading="loading">
        <div v-if="this.Orderlist.length !== 0">
        <template v-for="index in Orderlist"  >
          <div class="list-item" :key="index.order_id" v-if="index.length > 0">
            <div class="list-item-header" @click="goOrderDetails(index)">
              <div class="order-number">
                订单号：{{ index[0].order_id
                }}<span class="supplier"
                  >{{ index[0].company_name }} 等<i class="f-orange">{{
                    index[0].company_num
                  }}</i
                  >供应商</span
                >
              </div>
              <span class="status">{{
                 index[0].order_state == 1
                 ?"已取消"
                 :index[0].order_state == 2
                  ? "待确认"
                  : index[0].order_state == 3
                  ? "预约成功"
                  : index[0].order_state == 4
                  ? "预约失败"
                  : index[0].order_state == 5
                  ? "结算申请中"
                  : index[0].order_state == 6
                  ? "结算成功"
                  : index[0].order_state == 7
                  ? "结算失败"
                  : index[0].order_state == 8
                  ? "完结申请中"
                  : index[0].order_state == 9
                  ? "完结失败"
                  : "已完结"
              }}</span>
            </div>
            <div class="list-item-main">
              <div class="td device" @click="goOrderDetails(index)">
                <el-image
                  :src="index[0].show_img"
                  style="width: 60px; height: 60px; margin-right: 11px"
                >
                  <div slot="error" class="image-slot">
                    <img
                      src="~@/assets/images/temp/temp_03.png"
                      class="device-pic"
                      alt=""
                    />
                  </div> </el-image
                >项目名称：{{ index[0].name }}
              </div>
              <div class="td">
                {{ index[0].rental_fee }}元/{{
                  index[0].rental_type == 0
                    ? "日"
                    : index[0].rental_type == 1
                    ? "月"
                    : index[0].rental_type == 2
                    ? "半年"
                    : "年"
                }}
              </div>
              <div class="td">{{ index[0].rental_time }}{{
                  index[0].rental_type == 0
                    ? "日"
                    : index[0].rental_type == 1
                    ? "月"
                    : index[0].rental_type == 2
                    ? "半年"
                    : "年"
                }}</div>
              <div class="td">×{{ index[0].num }}</div>
              <div class="td">
                共{{ index[0].device_num }}台设备，预约{{ index[0].rental_time
                }}{{
                  index[0].rental_type == 0
                    ? "日"
                    : index[0].rental_type == 1
                    ? "月"
                    : index[0].rental_type == 2
                    ? "半年"
                    : "年"
                }}<br />预计：
                <!-- <span class="f-orange"
                  >¥{{ index[0].company_num *  index[0].num* index[0].rental_fee*index[0].rental_time }}</span
                > -->
                <span class="f-orange"  >¥{{ index[0].estimate_rental_fee  }}</span > 
                <div
                  style="margin-left: 10px; display: flex; padding-top: 20px"
                >
                  <el-button   size="small"  @click="tojs(index)" style="margin-left:88px" >查询结算</el-button  >
                  <el-button
                    size="small"
                    style="margin-left: 10px"
                    @click="centerDetails(index)"
                    v-if="
                      false
                    "
                    >中期结算</el-button
                  >
                  <el-button
                    size="small"
                    style="margin-left: 10px"
                    @click="OrderDetails(index)"
                    v-if="
                     false
                    "
                    >订单完结</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-else-if="Orderlist.length === 0 && !loading"  style="text-align: center">暂无数据</div>
      </div>
      
    </section>

    <div class="pagination">
      <el-pagination
        background
        layout="total,prev, pager, next,jumper"
        :page-size="page.pageSize"
        :current-page="page.pageNumber"
        :total="total.totalRow"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getOrder } from "../../api/myorder";
import kits from "@/kits/index.js";
export default {
  data() {
    return {
      Orderlist: [],
      rental_fee: 0,
      page: {
        pageNumber: 1,
        pageSize: 10,
      },
      queryInfo:{},
      total: {
        totalPage: null,
        totalRow: null,
      },
      loading:true
    };
  },
  created() {
    this.getmyorder();
  },
  methods: {
    goOrderDetails(index) {
      this.$router.push({
        path: `/personalCenter/orderDetails`,
        query: { id: index[0].order_id },
      });
    },
    tojs(index) {
        console.log("xxxxxxxxxxx-index",index)
        this.$router.push({
            path: `/personalCenter/applyBill`,
            query: { order_id: index[0].order_id },
        });
    },
    handleCurrentChange(val) {
      this.page.pageNumber = val;
      this.getmyorder();
      window.scrollTo(0, 0);
    },
    async getmyorder() {
      let res = await getOrder({
        inputs: {},
        pagination: this.page,
      });
      console.log(res);
      this.Orderlist = res.data.order_list;

      for (let i = 0; i < this.Orderlist.length; i++) {
        this.Orderlist[i][0].show_img = kits.img.url(this.Orderlist[i][0].show_img);
      }
      this.total.totalRow = res.data.total;
      this.loading = false
    },
  
  },
};
</script>

<style scoped>
</style>
